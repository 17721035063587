import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box } from '@rebass/grid';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import React, { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import euroFormat from '../../constants/euroFormat';
import { PartnerParams } from '../../entities/PartnerParams';
import { BookingConnectionFragment, BookingEdgeFragment } from '../../generated/graphql';
import useBigList, { CreateRow, CreateSkeletonRows } from '../../hooks/useBigList';
import createGuestsText from '../../utils/createGuestsText';
import { formatDuration } from '../../utils/formatDuration';
import { getBookingsStatus } from '../../utils/getBookingsStatus';
import { shouldHideBookingCustomer } from '../../utils/shouldHideBooking';
import BigListWrapper from '../atoms/BigListWrapper';
import UnitDetails from '../atoms/UnitDetails';
import { STEP } from '../pages/Bookings';
import { getGuestTitle } from '../pages/bookings/Details';
import BigList from './BigList';
import FilterUnitMenu from './FilterUnitMenu';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { FormattedMessage } from 'react-intl';

export const bookingListItemFragment = gql`
    fragment BookingListItem on Booking {
        id
        number
        amountAdults
        amountYouths
        amountChildren
        amountBabies
        amountPets
        departureDate
        arrivalDate
        updatedAt
        partnerComment
        status
        customer {
            id
            firstName
            lastName
        }
        partnerPriceLines {
            category
            totalPrice
        }
        duration
        rentalUnit {
            ...UnitDetails
        }
        totalPrice
    }
`;

interface BookingsListProps {
    entries?: Maybe<BookingConnectionFragment>;
    onLoadMore: (startIndex: number, stopIndex: number) => Promise<void>;
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    isLoading: boolean;
    isSearching?: boolean;
    noResultsText: string;
    withUnitFilter?: boolean;
}

const widths = [
    [3 / 16, null, 2 / 8],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
    [3 / 16, null, 1 / 8],
    1 / 8,
    [1 / 8, null, 3 / 16],
];

const BookingsList: FC<React.PropsWithChildren<BookingsListProps>> = memo(
    ({
        entries,
        onLoadMore,
        hasNextPage,
        isNextPageLoading,
        isLoading,
        noResultsText,
        isSearching,
        withUnitFilter,
    }) => {
        const edges: BookingEdgeFragment[] =
            entries?.edges?.filter((edge): edge is BookingEdgeFragment => !!edge?.node) ?? [];

        const isItemLoaded = (index: number) => !hasNextPage || index < edges.length;

        // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
        const loadMoreItems = isNextPageLoading ? () => {} : onLoadMore;

        const { createHeader, createSkeletonRows, createRow } = useBigList(widths);

        const Item: FC<React.PropsWithChildren<ListChildComponentProps>> = props => {
            if (!isItemLoaded(props.index)) {
                return createSkeletonRows(1, props.style);
            }
            return <BookingItem {...props} />;
        };

        return (
            <BigListWrapper>
                {createHeader([
                    withUnitFilter ? <FilterUnitMenu /> : <FormattedMessage defaultMessage="Accommodaties" />,
                    <FormattedMessage key="period" defaultMessage="Periode" />,
                    <FormattedMessage key="guest" defaultMessage="Gast en samenstelling" />,
                    <FormattedMessage key="updatedAt" defaultMessage="Laatst gewijzigd" />,
                    <FormattedMessage key="price" defaultMessage="Prijs" />,
                    <FormattedMessage key="status" defaultMessage="Status" />,
                ])}

                {!isSearching ? (
                    !isLoading && edges.length === 0 ? (
                        <Box p={4}>{noResultsText}</Box>
                    ) : (
                        <BigList
                            infiniteLoader={{
                                loadMoreItems,
                                isItemLoaded,
                                itemCount: entries?.totalCount ?? STEP,
                                minimumBatchSize: STEP,
                            }}
                            overscanCount={STEP * 2}
                            itemSize={78}
                            itemCount={entries?.totalCount ?? STEP}
                            itemData={{ edges, createRow, createSkeletonRows }}
                        >
                            {Item}
                        </BigList>
                    )
                ) : (
                    createSkeletonRows(STEP)
                )}
            </BigListWrapper>
        );
    }
);

export default BookingsList;

interface BookingItemProps extends ListChildComponentProps {
    data: {
        edges: BookingEdgeFragment[];
        createRow: CreateRow;
        createSkeletonRows: CreateSkeletonRows;
    };
}

const BookingItem: FC<React.PropsWithChildren<BookingItemProps>> = memo(
    ({ index, style, data: { createRow, createSkeletonRows, edges } }) => {
        const booking = edges[index]?.node;
        const { partnerId } = useParams<PartnerParams>();

        if (!booking) {
            return createSkeletonRows(1, style);
        }

        const {
            id,
            rentalUnit,
            arrivalDate,
            departureDate,
            partnerPriceLines,
            duration,
            customer,
            number,
            totalPrice,
            ...rest
        } = booking;

        const startDate = new Date(arrivalDate);
        const endDate = new Date(departureDate);
        const updatedAt = new Date(rest.updatedAt);
        const periodText = formatDuration(startDate, endDate);

        const status = getBookingsStatus(booking);

        return createRow(`/partner/${partnerId}/bookings/all/${id}`, style, [
            <StyledUnitDetails key={id} unit={{ ...rentalUnit }} />,
            { text: periodText, subText: `${duration} nachten` },
            {
                text: getGuestTitle(customer, shouldHideBookingCustomer(booking.status)),
                subText: customer ? createGuestsText(rest) : undefined,
            },
            { text: format(updatedAt, dateTextFormat, getDateOpts('nl')), subText: number ?? undefined },
            euroFormat(totalPrice),
            { text: status.text, subText: status.subText },
        ]);
    },
    areEqual
);

const StyledUnitDetails = styled(UnitDetails)`
    min-width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > :first-child {
            display: none;
        }
    }
`;
